import QRCode from 'qrcode'

export default function () {
  this.url = ''

  this.generate = function (callback) {
    QRCode.toDataURL(this.url, function (err, url) {
      if (!err) {
        callback(url)
      } else {
        callback()
      }
    })
  }
}
