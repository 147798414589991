export default function () {
  this.fontSize = 10
  this.columns = []
  this.rows = []
  this.doc = {}
  this.parent = {}
  this.pos = 0
  this.left = 15
  this.temp = {}
  this.page = 1
  this.title = ''
  this.location = ''
  this.contact = {}

  this.overviewHeader = function (data) {
    this.qr.url =
      this.parent.$flix_url + '/check-in/' + data.booking_id + '.html'
    this.qr.generate(
      function (qr) {
        if (qr) {
          this.doc.addImage(qr, 'png', 210 - this.left - 35, 10, 40, 40)
          this.doc.setFontSize(6)
          this.doc.text(210 - this.left, 50, this.qr.url, { align: 'right' })
        }
        // this.doc.setFont(this.doc.getFont(), , 'bold')
        this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
        this.doc.setFontSize(16)
        let page = ' 2.' + this.page
        if (Object.keys(this.rows).length === 1) {
          page = this.page + '.'
        }
        this.doc.text(this.left, 20, page)
        this.doc.setFontSize(16)
        this.doc.text(this.left * 3, 20, this.title)
        this.doc.setFontSize(14)
        this.doc.text(
          this.left * 3,
          26,
          data.booking_date
            .replace(/(<([^>]+)>)/gi, '')
            .split('&#xe212')
            .join('-')
            .split('&#xe046')
            .join('')
            .split('&#xe055')
            .join(', ')
            .split(' ,  ')
            .join(', ')
            .split('  ')
            .join(' ')
            .trim()
        )
        this.doc.setFontSize(8)
        this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
        var d = new Date()
        this.doc.text(
          210 - this.left,
          10,
          this.parent.$t('message.checkIn') +
            ' / ' +
            this.parent.$t('message.checkOut') +
            ' #' +
            data.booking_id,
          { align: 'right' }
        )
        this.doc.text(
          this.left,
          10,
          this.parent.$getNullsBefore(d.getDate()) +
            '.' +
            this.parent.$getNullsBefore(d.getMonth() + 1) +
            '.' +
            d.getFullYear() +
            ', ' +
            this.parent.$getNullsBefore(d.getHours()) +
            ':' +
            this.parent.$getNullsBefore(d.getMinutes())
        )
        this.doc.setDrawColor('#000000')
        this.doc.line(this.left, 32, 210 - this.left - 40, 32, 'F')
      }.bind(this)
    )
  }

  this.getLocation = function () {
    if (
      !this.location.split('\n').join('').trim() &&
      !Object.keys(this.contact).length
    ) {
      return false
    }

    if (this.location.split('\n').join('').trim()) {
      this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
      this.doc.setFontSize(10)
      this.doc.text(
        this.left,
        this.pos,
        this.parent.$tc('message.location', 1),
        { align: 'left', maxWidth: 210 / 2 - this.left }
      )
      this.pos += 4
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      let location = this.location.split('\n').join(' | ')
      this.doc.setFontSize(8)
      if (String(location).length > 120) {
        location = location.substr(0, 120) + ' ...'
      }
      this.doc.text(this.left, this.pos, location, {
        align: 'left',
        maxWidth: 210 / 2 - this.left
      })
    }

    if (Object.keys(this.contact).length) {
      this.pos -= 4
      this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
      this.doc.setFontSize(10)
      this.doc.text(
        210 / 2 + 210 / 2 - this.left,
        this.pos,
        this.parent.$tc('message.contact', 1),
        { align: 'right', maxWidth: 210 / 2 - this.left }
      )
      this.pos += 4
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      if (this.contact.name) {
        this.doc.setFontSize(8)
        this.doc.text(
          210 / 2 + 210 / 2 - this.left,
          this.pos,
          this.contact.name,
          { align: 'right', maxWidth: 210 / 2 - this.left }
        )
      }
      if (this.contact.name && this.contact.email) {
        this.pos += 4
      }
      if (this.contact.email) {
        this.doc.setFontSize(8)
        this.doc.text(
          210 / 2 + 210 / 2 - this.left,
          this.pos,
          this.contact.email,
          { align: 'right', maxWidth: 210 / 2 - this.left }
        )
      }
    }

    this.pos += 6
    this.doc.line(this.left, this.pos, 210 - this.left, this.pos, 'F')
    this.pos += 8
    this.doc.setDrawColor('#000000')
    this.doc.setFontSize(10)
  }

  this.getService = function (data) {
    if (
      typeof data.service === 'undefined' ||
      !data.service ||
      data.service === null ||
      data.service === 'null' ||
      data.service === 'false'
    ) {
      return false
    }
    if (typeof data.service !== 'object') {
      return false
    }

    this.pos += 2
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(10)
    this.doc.text(210 / 2, this.pos, this.parent.$tc('message.service', 1), {
      align: 'center'
    })
    this.pos += 4
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.text(210 / 2, this.pos, data.service.title, { align: 'center' })
    if (data.service.description) {
      this.pos += 6
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      this.doc.text(210 / 2, this.pos, data.service.description || '---', {
        align: 'center'
      })
    }
    if (data.service.price) {
      this.pos += 6
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      this.doc.text(210 / 2, this.pos, data.service.price + ' EUR' || '---', {
        align: 'center'
      })
    }
    this.pos += 6
    // this.doc.setDrawColor('#DDDDDD')
    this.doc.line(this.left, this.pos, 210 - this.left, this.pos, 'F')
    this.pos += 8
    this.doc.setDrawColor('#000000')
    this.doc.setFontSize(10)
  }

  this.generateContent = function (data) {
    var txt = ''
    Object.keys(data).forEach(
      function (k) {
        this.doc.setFontSize(10)
        if (!data[k]) {
          data[k] = '---'
        }
        this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
        txt = ''
        txt += k
        this.doc.text(this.left, this.pos, txt)
        this.doc.setFontSize(10)
        this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
        txt = ''
        var down = 15
        if (typeof data[k] === 'string') {
          data[k] = data[k].split('\n').join(' ').trim()
          down = 12 + 5 * Math.floor(data[k].length / 100)
        } else if (
          typeof data[k] === 'object' &&
          typeof data[k].src !== 'undefined'
        ) {
          data[k] = data[k].src
        }
        txt += data[k]
        this.doc.text(this.left, this.pos + 4, txt, {
          maxWidth: 210 - this.left * 2
        })
        this.pos += down
      }.bind(this)
    )
  }

  this.generatePDF = function () {
    this.pos = 30

    for (var i = 0; i < this.rows.length; i++) {
      this.overviewHeader(this.rows[i])
      this.pos += 15
      this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
      this.doc.setFontSize(20)
      this.doc.text(
        this.left,
        this.pos,
        this.parent.$t('message.' + this.rows[i].status),
        { align: 'left' }
      )
      this.pos += 5
      this.doc.setFontSize(10)
      this.doc.text(
        this.left,
        this.pos,
        this.parent.$getGroupTxt(this.rows[i], this.parent),
        { align: 'left' }
      )
      this.doc.setFontSize(20)
      if (this.rows[i].check_in) {
        this.doc.setTextColor('#555')
        this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
        this.doc.setFontSize(8)
        this.pos += 8
        this.doc.text(this.left, this.pos, this.rows[i].check_in, {
          align: 'left'
        })
      }
      if (this.rows[i].check_out) {
        this.doc.setTextColor('#555')
        this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
        this.doc.setFontSize(8)
        this.pos += 5
        this.doc.text(this.left, this.pos, this.rows[i].check_out, {
          align: 'left'
        })
      }
      this.doc.setTextColor('#000000')
      this.pos += 10
      this.doc.line(this.left, this.pos, 210 - this.left, this.pos, 'F')
      this.pos += 10
      this.pos += 0
      this.getLocation()
      this.getService(this.rows[i])
      this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
      this.doc.setFontSize(16)
      this.pos += 5
      this.doc.text(
        this.left,
        this.pos,
        this.parent.$t('message.personalData'),
        { align: 'left' }
      )
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      this.pos += 5
      this.doc.setFontSize(12)
      this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
      this.doc.text(
        this.left,
        this.pos,
        this.parent.$t('message.editNumber') + ' = ' + this.rows[i].booking_id,
        { align: 'left' }
      )
      this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
      this.pos += 10
      this.generateContent(this.rows[i].data)
      this.page++
      this.pos = 30
      if (i < this.rows.length - 1) {
        this.doc.addPage()
      }
    }
  }
}
