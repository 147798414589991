import jsPDF from 'jspdf'
import overview from './overview.js'
import details from './details.js'
import header from './header.js'
import qr from './qr.js'

export default function () {
  this.fontSize = 10
  this.columns = []
  this.rows = []
  this.doc = {}
  this.parent = {}
  this.pos = 0
  this.left = 15
  this.temp = {}
  this.page = 1
  this.title = ''
  this.location = ''
  this.contact = {}

  this.getHeader = function () {
    var o = new header()
    o.fontSize = this.fontSize
    o.columns = this.columns
    o.rows = this.rows
    o.doc = this.doc
    o.parent = this.parent
    o.pos = this.pos
    o.left = this.left
    o.temp = this.temp
    o.page = this.page
    o.title = this.title
    o.generatePDF()

    this.doc = o.doc
  }

  this.getOverview = function () {
    var o = new overview()
    o.fontSize = this.fontSize
    o.columns = this.columns
    o.rows = this.rows
    o.doc = this.doc
    o.parent = this.parent
    o.pos = this.pos
    o.left = this.left
    o.temp = this.temp
    o.page = this.page
    o.title = this.title
    o.generatePDF()

    this.doc = o.doc
  }

  this.getDetails = function () {
    var o = new details()
    o.fontSize = this.fontSize
    o.columns = this.columns
    o.rows = this.rows
    o.doc = this.doc
    o.parent = this.parent
    o.pos = this.pos
    o.left = this.left
    o.temp = this.temp
    o.page = this.page
    o.title = this.title
    o.qr = new qr()
    o.location = this.location
    o.contact = this.contact
    o.generatePDF()

    this.doc = o.doc
  }
  this.initializePDF = function () {
    this.doc = new jsPDF({
      orientation: 'portrait'
    })
  }
  this.generatePDF = function () {
    this.initializePDF()
    this.getHeader()
    this.doc.addPage()
    this.getOverview()
    this.doc.addPage()
    this.getDetails()
  }
}
