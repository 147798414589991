export default function () {
  this.fontSize = 10
  this.columns = []
  this.rows = []
  this.doc = {}
  this.parent = {}
  this.pos = 0
  this.left = 15
  this.temp = {}
  this.page = 1
  this.title = ''

  this.overviewHeader = function () {
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(18)
    this.doc.text(this.left, 20, ' 1.' + this.page)
    this.doc.text(
      this.left * 2,
      20,
      this.parent.$t('message.preview') +
        ' / ' +
        this.parent.$t('message.overview')
    )
    this.doc.setFontSize(10)
    this.doc.text(this.left * 2, 24, this.title)
    this.doc.setFontSize(8)
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    var d = new Date()
    this.doc.text(
      210 - this.left,
      10,
      this.parent.$getNullsBefore(d.getDate()) +
        '.' +
        this.parent.$getNullsBefore(d.getMonth() + 1) +
        '.' +
        d.getFullYear() +
        ', ' +
        this.parent.$getNullsBefore(d.getHours()) +
        ':' +
        this.parent.$getNullsBefore(d.getMinutes()),
      { align: 'right' }
    )
    this.doc.setDrawColor('#000000')
    this.doc.line(this.left, 27, 210 - this.left, 27, 'F')
  }

  this.getStatusBar = function () {
    const counter = {
      commitment: 0,
      cancellation: 0,
      open: 0
    }

    this.rows.forEach((entry) => {
      counter[entry.status] += entry.group ? entry.group * 1 : 1
    })
    this.pos += 40
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(16)

    let text = this.parent.$t('message.commitment') + ': ' + counter.commitment

    if (counter.cancellation) {
      text +=
        ', ' +
        this.parent.$t('message.cancellation') +
        ': ' +
        counter.cancellation
    }

    this.doc.text(this.left, this.pos, text)
  }

  this.checkPos = function () {
    if (this.pos >= 280) {
      this.doc.addPage()
      this.page++
      this.overviewHeader()
      this.temp.booking_date = ''
      this.pos = 30
    }
  }

  this.getOverview = function () {
    this.doc.setFontSize(8)
    this.temp.booking_date = ''
    this.pos = 45

    for (var i = 0; i < this.rows.length; i++) {
      this.checkPos()

      this.getBookingDate(this.rows[i])

      this.doc.setFontSize(8)
      this.doc.text(this.left, this.pos, String(2 + '.' + (i + 1)))
      this.doc.text(
        this.left * 2,
        this.pos,
        this.parent.$t('message.' + this.rows[i].status)
      )
      this.doc.text(
        this.left * 4,
        this.pos,
        this.parent.$getGroupTxt(this.rows[i], this.parent)
      )
      this.doc.text(
        this.left * 6,
        this.pos,
        this.rows[i].email.replace(/(<([^>]+)>)/gi, '').trim()
      )
      this.doc.text(210 - this.left, this.pos, '#' + this.rows[i].booking_id, {
        align: 'right'
      })
      this.pos += 4
      this.checkPos()
      this.getNotice(this.rows[i])
      this.checkPos()
      this.getService(this.rows[i])
      this.pos += 2
      this.doc.setFontSize(6)
      this.doc.setDrawColor('#DDDDDD')
      this.checkPos()
      Object.keys(this.rows[i].data).forEach((d_key) => {
        let content = this.rows[i].data[d_key]

        if (typeof content === 'object' && typeof content.src !== 'undefined') {
          content = content.src
        }

        this.doc.text(this.left, this.pos, String(d_key) + ': ' + content)
        this.pos += 3
        this.checkPos()
      })
      this.checkPos()
      this.pos += 2
      this.doc.setFontSize(8)
      this.doc.line(this.left, this.pos, 210 - this.left, this.pos, 'F')
      this.doc.setDrawColor('#000000')
      this.pos += 4
    }
  }

  this.getService = function (data) {
    if (
      typeof data.service === 'undefined' ||
      !data.service ||
      data.service === null ||
      data.service === 'null' ||
      data.service === 'false'
    ) {
      return false
    }
    if (typeof data.service !== 'object') {
      return false
    }

    this.pos += 2
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(8)
    this.doc.text(
      this.left * 2,
      this.pos,
      this.parent.$tc('message.service', 1)
    )
    if (data.service.price) {
      this.doc.text(
        210 - this.left,
        this.pos,
        this.parent.$tc('message.service', 1) +
          ' ' +
          this.parent.$tc('message.price', 1),
        { align: 'right' }
      )
    }
    this.pos += 4
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.text(this.left * 2, this.pos, data.service.title)
    if (data.service.price) {
      this.doc.text(210 - this.left, this.pos, data.service.price || '---', {
        align: 'right'
      })
    }
    this.pos += 5
  }

  this.getNotice = function (d) {
    var data = JSON.parse(JSON.stringify(d))

    if (typeof data.data[this.parent.$tc('message.note')] === 'undefined') {
      return false
    }

    this.pos += 2
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(8)
    this.doc.text(this.left * 2, this.pos, this.parent.$tc('message.note', 1))

    this.pos += 4
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.doc.text(
      this.left * 2,
      this.pos,
      data.data[this.parent.$tc('message.note')]
    )
    this.pos += 5
  }

  this.getBookingDate = function (row) {
    var booking_date = row.booking_date
      .replace(/(<([^>]+)>)/gi, '')
      .split('&#xe212')
      .join('-')
      .split('&#xe046')
      .join('')
      .split('&#xe055')
      .join(', ')
      .split(' ,  ')
      .join(', ')
      .split('  ')
      .join(' ')
      .trim()
    if (this.temp.booking_date === booking_date) {
      return false
    }
    this.temp.booking_date = booking_date
    this.pos += 5
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(12)
    this.doc.text(this.left, this.pos, this.temp.booking_date)
    // this.doc.text(210 - this.left, this.pos, 'ID: #' + row.booking_id, { align: 'right' })
    this.doc.setFontSize(10)
    this.pos += 5
    this.doc.setFontSize(8)
    this.doc.text(this.left, this.pos, this.parent.$tc('message.page', 1))
    this.doc.text(this.left * 2, this.pos, this.parent.$t('message.status'))
    this.doc.text(
      this.left * 4,
      this.pos,
      this.parent.$t('message.appointmentFormat')
    )
    this.doc.text(this.left * 6, this.pos, this.parent.$t('message.email'))
    this.doc.text(210 - this.left, this.pos, 'ID', { align: 'right' })
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
    this.pos += 5
  }
  this.generatePDF = function () {
    this.overviewHeader()
    this.getStatusBar()
    this.getOverview()
  }
}
