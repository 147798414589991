export default function () {
  this.fontSize = 10
  this.columns = []
  this.rows = []
  this.doc = {}
  this.parent = {}
  this.pos = 0
  this.left = 15
  this.temp = {}
  this.page = 1
  this.title = ''

  this.overviewHeader = function (data) {
    this.doc.setDrawColor('#000000')
    this.doc.line(this.left, this.pos - 20, 210 - this.left, this.pos - 20, 'F')
    this.doc.setFont(this.doc.getFont().fontName, 'bold', false)
    this.doc.setFontSize(18)
    this.doc.text((210 / 2), this.pos, this.title, { align: 'center' })
    this.doc.setFontSize(14)
    var d = new Date()
    this.doc.text((210 / 2), this.pos + 6, 'Version: ' + this.parent.$getNullsBefore(d.getDate()) + '.' + this.parent.$getNullsBefore((d.getMonth() + 1)) + '.' + d.getFullYear() + ', ' + this.parent.$getNullsBefore(d.getHours()) + ':' + this.parent.$getNullsBefore(d.getMinutes()), { align: 'center' })
    this.doc.line(this.left, this.pos + 20, 210 - this.left, this.pos + 20, 'F')
    this.doc.setFont(this.doc.getFont().fontName, 'normal', false)
  }

  this.generatePDF = function () {
    this.pos = ((297 / 2) - 10)
    this.overviewHeader(this.rows[0])
  }
}
