<template>
  <div>
    <!-- <iframe v-if="doc" :src="doc.output('dataurlstring')" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:90vw; height:100vh;" allowfullscreen></iframe> -->
    <div v-if="!ready" class="flix-text-center">
      <h2 class="flix-html-h2">{{ $t('message.generatePDF') }}</h2>
      <flixLoader />
    </div>
  </div>
</template>
<script>
import pdf from '@/components/pdf/pdf.js'
export default {
  components: {},
  props: {
    data: Array
  },
  data () {
    return {
      doc: false,
      notes: {},
      chats: {},
      ready: false,
      onSuccess: Function,
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getDoc () {
      var doc = new pdf()
      doc.rows = JSON.parse(JSON.stringify(this.data)).slice(0, 1000)
      doc.parent = this
      doc.title = this.$store.getters.assistentsByID[this.data[0].form].title

      doc.generatePDF()

      this.doc = doc.doc
      this.ready = true
      var d = new Date()
      this.doc.save(d.getFullYear() + '' + (d.getMonth() + 1) + '' + d.getDate() + '_' + d.getTime() + '.pdf')
    }
  },
  mounted () {
    this.getDoc()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
